<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import service from '../api/incotermService'
import incotermRuleIncoterm from '../api/incotermRuleIncotermService'
import { CrudAction } from '@brain/core'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getRuleMeta() {
      const ruleMeta = await incotermRuleIncoterm.getMeta()
      ruleMeta.name = 'incotermRuleIncoterm'
      ruleMeta.masterName = 'incotermId'
      ruleMeta.fields = ruleMeta.fields.filter(
        (field) => field.id != 'incoterm'
      )
      ruleMeta.detailsObject = {
        service: incotermRuleIncoterm
      }
      const removedPermissions = new Set([CrudAction.CREATE, CrudAction.DELETE])
      ruleMeta.permissions = ruleMeta.permissions.filter(
        (p) => !removedPermissions.has(p)
      )
      return ruleMeta
    },
    async getMetaDetails() {
      return await Promise.all([this.getRuleMeta()])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      const removedPermissions = new Set([CrudAction.CREATE, CrudAction.DELETE])
      meta.permissions = meta.permissions.filter(
        (p) => !removedPermissions.has(p)
      )
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      meta.details.forEach((detail) => {
        this.details[detail.name] = detail.detailsObject
      })
      this.metadata = meta
    })
  }
}
</script>

<style scoped>
::v-deep .ag-selection-checkbox {
  display: none !important;
}
::v-deep .table-toolbar__select {
  visibility: hidden;
}
</style>
